export type AuthStatus = 'None' | 'MsalAuthSuccess' | 'MsalAuthError';

let authStatus: AuthStatus = 'None';
let authError: string = '';
let fromCache: boolean | undefined;

export function setAuthStatusInfo(msalAuthStatus: AuthStatus, error: string, cache?: boolean) {
    authStatus = msalAuthStatus;
    authError = error;
    fromCache = cache;
}

export function getAuthStatusInfo() {
    return { authStatus, authError, fromCache };
}
