import { Module } from 'owa-workloads/lib/store/schema/Module';
import type { ServiceWorkerConfig } from 'owa-serviceworker-common/lib/types/ServiceWorkerConfig';

export function getServiceWorkerConfig(mod: Module): ServiceWorkerConfig | undefined {
    switch (mod) {
        case Module.Mail:
        case Module.Calendar:
        case Module.FilesHub:
            return {
                app: 'mail',
                expectedXAppNameHeader: 'Mail',
            };
    }
    return undefined;
}
