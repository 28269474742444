import type {
    ANDROID_OUTLOOK,
    HUB,
    IOS_OUTLOOK,
    MAC_OUTLOOK,
    NATIVE,
    O365_SHELL,
    OUTLOOK_DESKTOP,
    TEAMS,
    TEAMS_HUB,
    WIN32_OUTLOOK_HUB,
    ANDROID_TEAMS,
    IOS_TEAMS,
    ANDROID_OUTLOOK_LITE,
    OUTLOOK_EXTENSION,
    SHAREPOINT,
    LOOP_APP,
    OWA_HUB,
    MONARCH_HUB,
    ANDORID_OUTLOOK_HUB,
    IOS_OUTLOOK_HUB,
    MAC_OUTLOOK_HUB,
    OFFICE_WEB,
    OFFICE_DESKTOP,
} from 'owa-config';

// Very often we want to enable/disable a flag for all OPX scenarios at the same time.
// In order to not define a value for each OPX type we can use 'opx' fallback.
// In this case we are able to simplify a config, but still keep flexibility to define different values for each OPX type separately.
const OPX = 'opx';

// MetaOS
// HUB is the default set of values for when the app is hosted on the majority of the cases
// for special hubs where there few special deviations, we use WIN32_OUTLOOK_HUB or HUB
// to override any default value from HUB.
export type HUB_TYPES =
    | typeof WIN32_OUTLOOK_HUB
    | typeof TEAMS_HUB
    | typeof OWA_HUB
    | typeof MONARCH_HUB
    | typeof ANDORID_OUTLOOK_HUB
    | typeof IOS_OUTLOOK_HUB
    | typeof MAC_OUTLOOK_HUB
    | typeof HUB
    | typeof OFFICE_WEB
    | typeof OFFICE_DESKTOP;

export type HOST_APP_TYPES =
    | typeof OUTLOOK_DESKTOP
    | typeof MAC_OUTLOOK
    | typeof IOS_OUTLOOK
    | typeof ANDROID_OUTLOOK
    | typeof NATIVE
    | typeof TEAMS
    | typeof O365_SHELL
    | typeof OPX
    | typeof ANDROID_TEAMS
    | typeof IOS_TEAMS
    | typeof ANDROID_OUTLOOK_LITE
    | typeof OUTLOOK_EXTENSION
    | typeof SHAREPOINT
    | typeof LOOP_APP;

export type HOST_APP_FLAG = {
    default: boolean;
} & {
    [key in HOST_APP_TYPES]?: boolean;
} & {
    [key in HUB_TYPES]?: boolean;
};

const flags: {
    [key: string]: HOST_APP_FLAG;
} = {
    /** Allow host app to specify theming configuration */
    loadThemeFromHostApp: {
        default: false,
        opx: true,
    },
    /** Allow host app to specify culture configuration */
    loadCultureFromHostApp: {
        default: false,
        opx: true,
    },
    opxDeeplink: {
        default: false,
        opx: true,
    },
    opxComponentLifecycle: {
        default: false,
        native: false,
        opx: true,
    },
    scenarioData: {
        default: false,
        opx: true,
    },
    macA11y: {
        default: false,
        macoutlook: true,
    },
    joinTeamsMeetingHandler: {
        default: false,
        hub: true,
    },
    chatWithParticipantsHandler: {
        default: false,
        teamshub: true,
    },
    copyOnlineMeeting: {
        default: false,
        teamshub: true,
    },
    use3SPeopleSuggestions: {
        default: true,
        macoutlook: false,
    },
    roomAddedHandler: {
        default: false,
        opx: true,
    },
    roomFinderFreeBusyStyles: {
        default: false,
        macoutlook: true,
        outlookdesktop: true,
    },
    defaultSelectCalendarItemSvg: {
        default: true,
        opx: false,
    },
    opxInboxRules: {
        default: false,
        opx: true,
    },
    moduleNameFromWindow: {
        default: true,
        opx: false,
    },
    ribbonFeedback: {
        default: false,
        hub: true,
    },
    composeFrom: {
        default: false,
        opx: true,
    },
    groupDiscoverCloseButton: {
        default: false,
        opx: true,
    },
    updateModalProps: {
        default: false,
        opx: true,
    },
    modalOpenedHandler: {
        default: false,
        opx: true,
    },
    defaultOutlookThemeColor: {
        default: true,
        opx: false,
    },
    yammerHostClientType: {
        default: false,
        native: true,
        opx: true,
    },
    linkClicked: {
        default: false,
        outlookdesktop: true,
    },
    showMinimumThreeInsights: {
        default: false,
        opx: true,
    },
    groupCreatedHandler: {
        default: false,
        opx: true,
    },
    groupDeletedHandler: {
        default: false,
        opx: true,
    },
    updateSearchAnswerOnQueryChange: {
        default: false,
        opx: true,
    },
    insightsCountUpdatedHandler: {
        default: false,
        opx: true,
    },
    processMeetingInsightsHandler: {
        default: false,
        opx: true,
    },
    processEmptyYammerPublishersHandler: {
        default: false,
        opx: true,
    },
    focusZoneCicularNavigation: {
        default: false,
        opx: true,
    },
    hideAddCalendarLeftNav: {
        default: false,
        teamshub: true,
    },
    hidePersonalBookingsCalendarLeftNav: {
        default: false,
        teamshub: true,
    },
    hideAppSuiteHeader: {
        default: false,
        hub: true,
    },
    hideAppBar: {
        default: false,
        hub: true,
        opx: true,
    },
    openMailInHub: {
        default: false,
        win32outlookhub: true,
    },
    openComposeMailInHub: {
        default: false,
        win32outlookhub: true,
    },
    teamsOnlineMeetingExtraSettingsOpenLinkInWeb: {
        default: false,
        teamshub: true,
    },
    /** Indicates whether Settings pane is available */
    settings: {
        default: true,
        opx: false,
        hub: false,
    },
    /** Enables multi-account (connected personal accounts) infrastructure */
    multiAccounts: {
        default: true,
        teams: false,
        outlookdesktop: false, // Intentionally disabled in Win32 OPX to avoid multi-account experience inconsistencies with host app
        native: false, // Disabled in Monarch in favor of 'acctmonaccounts' (see isMonarchMultipleAccountsEnabled)
        hub: false, // Intentionally disabled in MetaOS to avoid multi-account experience inconsistencies
    },
    metaOSFeedback: {
        default: false,
        hub: true,
    },
    monarchFeedback: {
        default: false,
        native: true,
    },
    /** When true, skip await LPC config so LPC bootstrap doesn't block for 45 seconds */
    skipAwaitLpcConfig: {
        default: false,
        opx: true,
    },
    /** When true, allow read/write of per-workload scenario settings */
    workloadScenarioSettings: {
        default: true,
        opx: false,
    },
    /** When true, if the ribbon falls back to the default, immediately persist it **/
    ribbonPersistDefault: {
        default: false,
        native: true,
    },
    /** When true, Floodgate engine can be loaded/used */
    floodgate: {
        default: true,
        opx: false,
        outlookdesktop: true,
    },
    platformAppSdk: {
        default: false,
        hub: true,
    },
    resourceTokenFromHost: {
        default: false,
        native: true,
        opx: true,
    },
    useBaseTheme: {
        default: false,
        win32outlookhub: true,
        hub: false,
    },
    useTeamsAppearance: {
        default: false,
        teamshub: true,
    },
    acctmonaccounts: {
        default: false,
        native: true,
    },
    nativeMessaging: {
        default: false,
        native: true,
    },
    disableAppModuleKeys: {
        default: false,
        hub: true,
    },
    openInNewWindow: {
        default: false,
        native: true,
    },
    deepLinkModuleSwitch: {
        default: true,
        native: true,
        opx: false,
    },
    hideEventifyLeftNav: {
        default: false,
        hub: true,
    },
    showOfficeNeutralTheme: {
        default: true,
        native: true,
        opx: false,
        hub: false,
    },
    analyticsWorker: {
        default: true,
        native: true,
        opx: false,
        hub: false,
    },
    supportsProjection: {
        default: true,
        opx: false,
        win32outlookhub: false,
    },
    offlineDataAllowed: {
        default: false,
        native: true,
        teamshub: true,
    },
    localCacheAllowed: {
        default: true,
        native: false,
        opx: false,
        hub: false,
    },
    sharedActivityBasedTimeout: {
        default: true,
        native: false,
        opx: false,
    },
    dataWorkerAllowed: {
        default: true,
        opx: false,
    },
    authRedirectOnSessionTimeout: {
        default: true,
        native: false,
        opx: false,
        hub: false, //MetaOS is not cookie based, the app depends on the MetaOS sdk to request a token from the Hub
    },
    altForKeytips: {
        default: false,
        native: true,
        win32outlookhub: true,
    },
    msalTokenFetch: {
        default: true,
        native: false,
        opx: false,
    },
    componentTokenProvider: {
        default: false,
        opx: true,
        native: true,
        hub: true,
    },
    nativeFriendlyKeyboarding: {
        default: false,
        native: true,
        win32outlookhub: true,
    },
    openEventsInNewWindow: {
        default: false,
        native: true,
    },
};

/**
 * @deprecated The method is deprecate.
 * Please, check https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/7497/In-Code-Host-Flag-Deprecation for guidance
 */
export const getFlagDefaults = (flagName: string) => {
    return flags[flagName];
};
